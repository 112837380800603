import React, { useEffect, useState } from 'react';
import StateUpdators from '../lib/StateUpdators';
import Utils from '../lib/Utils';
import LoginForm from './tools/LoginForm';

const Documentation = () => {
    console.log('{Documentation}');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    StateUpdators.setIsLoggedIn = setIsLoggedIn;
    useEffect(() => {

    },[]);
    return(
        <div className='home animate__animated animate__fadeIn tuh-tile-bkg'>
            {isLoggedIn ? <LoggedIn /> : <LoginForm />}
        </div>
    )
}

/**
 * When is loggedIn, redirect the user to the default userdefined screen based on user type
 */
const LoggedIn = () => {
    console.log('{LoggedIn}');
    useState(() => {
        Utils.defaultUserScreen();
    },[]);
    return (<></>)
}

export default Documentation;