import React from 'react';
import  { BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './views/Home';
import Documentation from './views/Documentation';
import AdminHome from './views/AdminHome';
import Action from './views/Action';
import Logout from './views/Logout';
import PathNotFound from './views/PathNotFound';

/**
 * The main entry point to the application
 * @returns React View
 */
const App = () => {
    console.log('{App}');
    return(
        <BrowserRouter basename={'/'}>
            <Routes>
                <Route path='/:reason?/:returnURL?' element={<Home />} />
                <Route path='logout' element={<Logout />} />
                <Route path='docs/:section?/:topic?' element={<Documentation />} />
                <Route path='home' element={<AdminHome />} />
                <Route path='adminAction/:token?/:data?' element={<Action />} />
                <Route path='*' element={<PathNotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;