/*
USSD Task Statutes
*/

const Statuses = {
    1: 'RUNNABLE',
    2: 'RUNNING',
    3: 'COMPLETE',
}

export default Statuses;