import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Assets from '../../lib/Assets';
import StateUpdators from '../../lib/StateUpdators';
import Utils from '../../lib/Utils';
import { useParams } from 'react-router-dom';

/**
 * The Login 
 * @returns 
 */
const LoginForm = () => {
    console.log('{LoginForm}');
    const [formInput, setFormInput] = useState({
        username: '',
        password: ''
    });
    const [errors, setLoginFormErrors] = useState({
        error: false,
        msg: ''
    });
    const params = useParams();
    let returnURL = params.returnURL ?? false;
    returnURL = returnURL ? Utils.base64Decode(returnURL) : false;
    const _returnURL = useRef(returnURL);
    StateUpdators.setFormInput = setFormInput;
    StateUpdators.setLoginFormErrors = setLoginFormErrors;
    Utils.loginInput = formInput;
    useEffect(() => {
        Utils.setPageTitle({title: 'Login'});
        Utils.setClickEventListner({returnURL: _returnURL.current});
        if(params.reason && params.reason === 'loginFirst') {
            setLoginFormErrors({
                error: true,
                msg: 'You need to login first'
            });
        }
        return undefined;
    },[params.reason]);
    return(
        <>
        <div className='login-form animate__animated animate__fadeIn rounded'>
            <div className='input-logo centered' title='Topuphome Internals'>
                <img className='pointer' src={Assets.TopuphomeLogoInternals} alt='Topuphome Logo' />
            </div>
            {
                errors.error && 
                <div className='error centered width-80 animate__animated animate__fadeIn'>
                    <span>
                        {errors.msg}
                    </span>
                </div>
            }
            <div className='form-input-content centered width-80'>
                <p>Username:</p>
                <input className='input-form-the rounded' value={formInput.username} type={'text'} placeholder='email address' onChange={(e) => {
                    setFormInput({
                        ...formInput,
                        username: e.currentTarget.value ? e.currentTarget.value : ''
                    });
                }} onFocus={() => {
                    setLoginFormErrors({error: false});
                }} />
                <p>Password:</p>
                <input className='input-form-the rounded' value={formInput.password} type={'password'} placeholder='password' onChange={(e) => {
                    setFormInput({
                        ...formInput,
                        password: e.currentTarget.value ? e.currentTarget.value : ''
                    });
                }} onFocus={() => {
                    setLoginFormErrors({error: false});
                }}/>
            </div>
            <div className='btn-login width-80 centered content-right'>
                <Button id='btn-rounded' variant='contained' title='Login' onClick={() => {
                    if(!formInput.username || !formInput.password) {
                        setLoginFormErrors({
                            error: true,
                            msg: 'Please provide a valid username and password to login.'
                        });
                        return;
                    } else {
                        setLoginFormErrors({error: false, msg: ''});
                    }
                    Utils.login({
                        ...formInput,
                        returnURL: returnURL
                    });
                }}>
                    Login
                </Button>
            </div>
            <div className='need-help-text centered width-80'>
                <p onClick={() => {
                    Utils.open('docs/internals/signin');
                }} className='pointer' title='Need help signing in?'>Need help signing in?</p>
            </div>
        </div>
        </>
    )
}

export default LoginForm;