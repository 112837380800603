import React from 'react';
import AdminActions from '../../lib/AdminActions';
import AdminActionsFrontends from '../../lib/AdminActionsFrontends';
import Utils from '../../lib/Utils';
import { Button } from '@mui/material';

/**
 * Admin actions view
 * @returns 
 */
const AdminActionsView = () => {
    console.log('{AdminActionsView}');
    const sessData = Utils.retrieveSession();
    let staffActions = {};
    if(typeof sessData.profile !== 'undefined' && typeof sessData.staffActions !== 'undefined') {
        staffActions = sessData.staffActions;
    }
    staffActions.actions = sessData.staffActions.actions.filter(action => Utils.viewLessActions.indexOf(action.action) < 0);
    const onTopuphome = staffActions.actions.filter(action => action.platform === 'Topuphome');
    const onTranzact = staffActions.actions.filter(action => action.platform === 'Tranzact');
    return (
        <>
        <div className='staff-actions n-j-g centered'>
            <div className='on-tuh bcbh-ghgh'>
                <div className='width-90 shadow centred'>
                    <div className='on-plat-hrd box-b rounded-top'>
                        <h3>On Topuphome</h3>
                    </div>
                    <div className='the-actions-jmghghg'>
                    {
                        onTopuphome.map((action,i) => {
                            //console.log('action=',action);
                            return (
                                <div key={i}>
                                    <Button onClick={() => {
                                        const token = {
                                            action: AdminActionsFrontends[action.action]
                                        };
                                        window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`
                                    }} variant='contained' title={AdminActions[action.action]} className='action-buttons-hdgfh'>
                                        {AdminActions[action.action]}
                                    </Button>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
            <div className='on-trzct bcbh-ghgh'>
                <div className='width-90 shadow centred'>
                    <div className='on-plat-hrd box-b rounded-top'>
                        <h3>On Tranzact</h3>
                    </div>
                    <div className='the-actions-jmghghg'>
                    {
                        onTranzact.map((action,i) => {
                            return (
                                <div key={i}>
                                    <Button onClick={() => {
                                        const token = {
                                            action: AdminActionsFrontends[action.action]
                                        };
                                        window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`
                                    }} variant='contained' title={AdminActions[action.action]} className='action-buttons-hdgfh'>
                                        {AdminActions[action.action]}
                                    </Button>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default AdminActionsView;