import React, {useEffect, useRef, useState} from 'react';
import StateUpdators from '../../lib/StateUpdators';
import AdminColors from '../../lib/AdminColors';
import Assets from '../../lib/Assets';

/**
 * Manges display of some information in a non-disturbing way
 * @returns React View/Comp
 */
const SidePopin = () => {
    console.log('{SidePopin}');
    const [show, setShow] = useState(false);
    const [showMode, setShowMode] = useState('animate__fadeInRight');
    const [visualConfigs,setVisualConfigs] = useState({
        text: 'This is a test success message...',
        color: AdminColors.Primary.Green
    });

    const THREE_SEC = 3000;

    StateUpdators.setShow = setShow;
    StateUpdators.setShowMode = setShowMode;
    StateUpdators.setVisualConfigs = setVisualConfigs;

    const timer = useRef();

    timer.current = setTimeout(() => {
        setShowMode('animate__fadeOutRight');
    }, THREE_SEC);

    useEffect(() => {
        return () => clearTimeout(timer.current);
    });

    return (
        <>
        {show && 
            <div id='side-popin' className={`animate__animated shadow ${showMode}`}>
                <div id='text-dvlsk'>
                    <p style={{
                        color: visualConfigs.color
                    }}>{visualConfigs.text}</p>
                </div>
                <div id='icon-dicvhdh'>
                    <img src={Assets.closeWindow} onClick={() => {
                        setShow(false);
                    }} title='Dismiss' id='close-popin' alt='Close Window'/>
                </div>
            </div>}
        </>
    )
}

export default SidePopin;