import React, { Suspense, useEffect } from 'react';
import Utils from '../lib/Utils';
import AdminActionsFrontends from '../lib/AdminActionsFrontends';
import AdminActions from '../lib/AdminActions';
import ProgressBar from './tools/ProgressBar';
import SidePopin from './tools/SidePopin';
import Error from './tools/Error';
import Menu from './tools/Menu';
import { useParams } from 'react-router-dom';
import ActionModal from './tools/ActionModal';

/**
 * Action view
 * @returns 
 */
const Action = () => {
    console.log('{Action}');
    const params = useParams();
    const token = JSON.parse(Utils.base64Decode(params.token));
    const staffActions = Utils.flipObject(AdminActionsFrontends);
    const ActionView = React.lazy(() => import(`./tools/staffActions/${staffActions[token.action]??'InValidStaffAction'}`));
    useEffect(() => {
        Utils.setPageTitle(AdminActions[staffActions[token.action]]);
    },[staffActions,params,token.action]);
    return (
        <>
        <Suspense fallback={<div>Loading...</div>}>
            <Menu />
            <div className='action-view-container animate__animated animate__fadeIn'>
                <ActionView />
            </div>
        </Suspense>
        <ProgressBar />
        <SidePopin />
        <Error />
        <ActionModal />
        </>
    )
}

export default Action;