import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Displays confirmation actions
 * @returns
 */
const ActionModal = () => {
    console.log('{ActionModal}');
    const [config, setCActionMdoalInfo] = useState({
        show: false,
        View: () => (<></>)
    });
    StateUpdators.setCActionMdoalInfo = setCActionMdoalInfo;
    return (
        <>
        <Dialog open={config.show}>
            {
                config.show &&
                <div className='action-modal-kdjshs smart-darky-bdr rounded shadow width-70 animate__animated box-b animate__fadeIn'>
                    <div className='jsjspls-pw ptr' title='Close' onClick={() => {
                        setCActionMdoalInfo({show: false, View: () => <></>})
                    }}>
                        <CloseIcon sx={{fontSize: 40}} />
                    </div>
                    {
                        <config.View />
                    }
                </div>
            }
        </Dialog>
        </>
    )
}

export default ActionModal;