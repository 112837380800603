import React, { useEffect } from 'react';
import Utils from '../lib/Utils';
import ProgressBar from './tools/ProgressBar';
import SidePopin from './tools/SidePopin';
import Error from './tools/Error';

const Logout = () => {
    console.log('{Logout}');
    useEffect(() => {
        Utils.logoutUser();
    },[]);
    return(
        <>
        <div className='home animate__animated animate__fadeIn tuh-tile-bkg'>
        </div>
        <ProgressBar />
        <SidePopin />
        <Error />
        </>
    )
}

export default Logout;