/**
 * These are admin actions possible on this portal. Theya are the callables' handles
 * Learn more here: https://internals.topuphome.co.za/docs/admintasks/adminactions
 */

const AdminActionsFrontends = {
    'AddTranzactMerchant': 'addTrzctMerchant',
    'DeleteTranzactMerchant': 'deleteTrzctMerchant',
    'DeactivateTranzactMerchant': 'deactivateTrzctMerchant',
    'ActivateTranzactMerchant': 'activateTrzctMerchant',
    'UpdateTranzactMerchant': 'updateTrzctMerchant',
    'ProcessTranzactOrder': 'processTrzctOrder',
    'PlaceTranzactOrder': 'placeTrzctOrder',
    'ViewTopuphomeTransactions': 'vewTuhTrxs',
    'ViewTranzactTransactions': 'viewTrzctTrxs',
    'ViewTranzactMerchants': 'viewTrzctMerchant',
    'ViewTopuphomeUsers': 'viewTuhUsrs',
    'AddTopuphomeUser': 'addTuhUsr',
    'DeleteTopuphomeUser': 'deleteTuhUsr',
    'ActivateTopuphomeUser': 'ActivateTuhUsr',
    'DeactivateTopuphomeUser': 'deactivateTuhUsr',
    'UpdateTopuphomeUser': 'updateTuhUsr',
    'GrantAdminActions': 'grantAdminAction',
    'ViewTranzactMerchantBalances': 'viewTrzctMerchantBalances',
    'SettleTranzactOrder': 'settleTranzactOrder',
    'ViewTranzactOrders': 'viewTranzactOrders',
    'SendSystemNotice': 'sendSystemNotice',
    'Inventory': 'inventory',
    'ViewTopuphomeAgents': 'topuphomeAgents',
    'AllocateTopuphomeAgentValue': 'allocateAgentValue',
    'SettleTopuphomeAgentAllocation': 'settleAgentAllocation',
    'ViewResellerOrders': 'viewResellerOrders',
    'USSDSystemsStatus': 'ussdSystemsStatus',
    'UserDigitalGiftCards': 'userDigitalGiftCards',
    'ViewUSSDTasks': 'viewUSSDTasks'
}

export default AdminActionsFrontends;