/**
 * These are admin actions possible on this portal.
 * Learn more here: https://internals.topuphome.co.za/docs?section=admintasks&topic=adminactions
 */

const AdminActions = {
    'AddTranzactMerchant': 'Add a Tranzact Merchant',
    'DeleteTranzactMerchant': 'Delete a Tranzact Merchant',
    'DeactivateTranzactMerchant': 'Deactivate a Tranzact Merchant',
    'ActivateTranzactMerchant': 'Activate a Tranzact Merchant',
    'UpdateTranzactMerchant': 'Update a Tranzact Merchant',
    'ProcessTranzactOrder': 'Process a Tranzact Order',
    'PlaceTranzactOrder': 'Place a Tranzact Order',
    'ViewTopuphomeTransactions': 'Topuphome Transactions',
    'ViewTranzactTransactions': 'Tranzact Transactions',
    'ViewTranzactMerchants': 'Tranzact Merchants',
    'ViewTopuphomeUsers': 'Topuphome Users',
    'AddTopuphomeUser': 'Add a Topuphome User',
    'DeleteTopuphomeUser': 'Delete a Topuphome User',
    'ActivateTopuphomeUser': 'Activate a Topuphome User',
    'DeactivateTopuphomeUser': 'Deactivate a Topuphome User',
    'UpdateTopuphomeUser': 'Update a Topuphome User',
    'GrantAdminActions': 'Grant Admin Action',
    'ViewTranzactMerchantBalances': 'Tranzact Merchant Balances',
    'SettleTranzactOrder': 'Settle Tranzact Orders',
    'ViewTranzactOrders': 'Tranzact Orders',
    'SendSystemNotice': 'System Notices',
    'Inventory': 'Inventory',
    'ViewTopuphomeAgents': 'Topuphome Agents',
    'AllocateTopuphomeAgentValue': 'Allocate Agent Value',
    'SettleTopuphomeAgentAllocation': 'Settle Agent Allocation',
    'ProcessTopuphomeAgentOrder': 'Process Agent Order',
    'ViewResellerOrders': 'Reseller Orders',
    'USSDSystemsStatus': 'USSD Systems Status',
    'UserDigitalGiftCards': 'User Digital Gift Cards',
    'ViewUSSDTasks': 'USSD Tasks'
}

export default AdminActions;