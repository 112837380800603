import React, { useState } from 'react';
import Assets from '../../lib/Assets';
import StateUpdators from '../../lib/StateUpdators';

/**
 * 
 * @returns The Menu Icon View
 */
const MenuIcon = () => {
    console.log('{MenuIcon}');
    const [currentIcon, setCurrentIcon] = useState({
        icon: Assets.iconMenu,
        showMenu: true
    });
    return (
        <>
            <img className='pointer' alt='Menu' title='Menu' src={currentIcon.icon} id='menu-icons-nvhfh' onClick={() => {
                if(currentIcon.showMenu) {
                    setCurrentIcon({
                        icon: Assets.horizontalIcon,
                        state: false
                    });
                    StateUpdators.setShowMenu(true);
                } else {
                    setCurrentIcon({
                        icon: Assets.iconMenu,
                        showMenu: true
                    });
                    StateUpdators.setShowMenu(false);
                }
            }} />
        </>
    )
}

export default MenuIcon;