/**
 * Texts module
 */
const Texts = {
    success: {
        updateDGC: 'The user Digital Gift Card was updated successfully.'
    },
    error: {

    }
};


export default Texts;