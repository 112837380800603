/**
 * State Updators. Interact with React State management API
 */
const StateUpdators = {
    setUSSDTasks: () => {},
    setCActionMdoalInfo: () => {},
    setGigitalGiftCards: () => {},
    setUnSettledAgentOrders: () => {},
    setAgentsForSelect: () => {},
    setAgentDataStatus: () => {},
    setAgents: () => {},
    setUsersForSelect: () => {},
    setAudience: () => {},
    setServices: () => {},
    setAudienceChanged: () => {},
    setUsersLoaded: () => {},
    setAudienceType: () => {},
    setValidatorView: () => {},
    setShowMenu: () => {},
    setTransactOrdersView: () => {},
    setTransactionsView: () => {},
    setIsLoggedIn: () => {},
    setProgressBarConfigs: () => {},
    setLoginFormErrors: () => {},
    setShow: () => {},
    setShowMode: () => {},
    setVisualConfigs: () => {},
    setShowFatalError: () => {},
    setMerchants: () => {},
    setActionTitle: () => {},
    setMerchatDataStatus: () => {},
    setConfirmationInfo: () => {},
    setDisplayRequestData: () => {},
    setMerchantsForSelect: () => {},
    setUnSettledOrder: () => {},
    setTrzctDailyStats: () => {},
    setMerchantBalances: () => {},
    setTransIdValId: () => {},
    setShowSingleUserOnTransactionsView: () => {},
    setShowSingleMerchantOnTransactionsView: () => {},
    setAloadAtAll: () => {},
    setSearchResults: () => {},
    setDescription: () => {},
}

export default StateUpdators;