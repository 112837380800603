import React, { useEffect, useState } from 'react';
import StateUpdators from '../lib/StateUpdators';
import Utils from '../lib/Utils';
import LoginForm from './tools/LoginForm';
import ProgressBar from './tools/ProgressBar';
import SidePopin from './tools/SidePopin';
import Error from './tools/Error';

/**
 * 
 * @returns 
 */
const Home = () => {
    console.log('{Home}');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    StateUpdators.setIsLoggedIn = setIsLoggedIn;
    useEffect(() => {
        Utils.checkSession();
    },[]);
    return(
        <>
        <div className='home animate__animated animate__fadeIn tuh-tile-bkg'>
            {isLoggedIn ? <LoggedIn /> : <LoginForm />}
        </div>
        <ProgressBar />
        <SidePopin />
        <Error />
        </>
    )
}

/**
 * When is loggedIn, redirect the user to the default userdefined screen based on user type
 */
const LoggedIn = () => {
    console.log('{LoggedIn}');
    useEffect(() => {
        window.location = '/home';
    },[]);
    return (<></>)
}

export default Home;