import React, {useState} from 'react';
import StateUpdators from '../../lib/StateUpdators';
import Assets from '../../lib/Assets';

/**
 * Error view
 * @returns 
 */
const Error = () => {
    console.log('{Error}');
    const [error, setShowFatalError] = useState({
        error: false,
        msg: 'The error message'
    });
    StateUpdators.setShowFatalError = setShowFatalError;
    return (
        <>
        {
            error.error &&
            <div className='fatalError rounded animate__animated animate__fadeIn shadow'>
                <img id='close-icon-jdhsjsjh' className='pointer' src={Assets.closeWindow} title='Dismiss' alt='Dismiss' onClick={() => {
                    StateUpdators.setShowFatalError({
                        error: false
                    })
                }}/>
                <img id='error-icon-sjsh' className='pointer' src={Assets.warnRed} title='There was an error' alt='Error'/>
                <h3>Error:</h3>
                <p>{error.msg}</p>
            </div>
        }
        </>
    )
}

export default Error;