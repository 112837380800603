import React from 'react';
import Statuses from '../../lib/USSDTaskStatus';
import { Button } from '@mui/material';
import Utils from '../../lib/Utils';

/**
 * USSD tasks
 * @returns 
 */
const USSDTaskDetails = ({task}) => {
    console.log('{USSDTaskDetails}');
    //console.log('{USSDTaskDetails}: task=',task);
    const status = Statuses[task.state]??'FAILED';
    return (
        <>
        <div className='jhsg-kfjdh'>
            <div className='content-centre brd-btm-grey'>
                <h3>USSD Task Details</h3>
            </div>
            <div className='msjs-ldkjdj'>
                <div className='kdjdjd-ldd brd-btm-grey'>
                    <div className='mh-pejslsp content-centre'>
                        <p>Task Id:</p>
                    </div>
                    <div className='nbd-peied-ws content-centre'>
                        <p>{task.taskId}</p>
                    </div>
                </div>

                <div className='kdjdjd-ldd brd-btm-grey'>
                    <div className='mh-pejslsp content-centre'>
                        <p>Status:</p>
                    </div>
                    <div className='nbd-peied-ws content-centre'>
                        <p><span className={`row-staths-${status}`}>{status}</span></p>
                    </div>
                </div>

                <div className='kdjdjd-ldd brd-btm-grey'>
                    <div className='mh-pejslsp content-centre'>
                        <p>Approximate Running Time:</p>
                    </div>
                    <div className='nbd-peied-ws content-centre'>
                        <p>{task.duration} sec</p>
                    </div>
                </div>

                <div className='kdjdjd-ldd brd-btm-grey'>
                    <div className='mh-pejslsp content-centre'>
                        <p>Transaction Type:</p>
                    </div>
                    <div className='nbd-peied-ws content-centre'>
                        <p>{task.trxType}</p>
                    </div>
                </div>

                {
                    task.zombieCount &&
                    <>
                    <div className='kdjdjd-ldd brd-btm-grey'>
                        <div className='mh-pejslsp content-centre'>
                            <p>Zombie Count:</p>
                        </div>
                        <div className='nbd-peied-ws content-centre'>
                            <p>{task.zombieCount}</p>
                        </div>
                    </div>

                    <div className='kdjdjd-ldd brd-btm-grey'>
                        <div className='mh-pejslsp content-centre'>
                            <p>In Zombie State Duration:</p>
                        </div>
                        <div className='nbd-peied-ws content-centre'>
                            <p>{task.zombieTaskDetails.seconds} sec</p>
                        </div>
                    </div>
                    </>
                }

                {
                    task.givenUpCount ?
                    <>
                    <div className='kdjdjd-ldd brd-btm-grey'>
                        <div className='mh-pejslsp content-centre'>
                            <p>Given Up Count:</p>
                        </div>
                        <div className='nbd-peied-ws content-centre'>
                            <p>{task.givenUpCount}</p>
                        </div>
                    </div>
                    </> : <></>
                }

                <div className='kdjdjd-ldd brd-btm-grey'>
                    <div className='mh-pejslsp content-centre'>
                        <p>Recipient:</p>
                    </div>
                    <div className='nbd-peied-ws content-centre'>
                        <p>{task.recipient.cellphone}</p>
                    </div>
                </div>

                <div className='kdjdjd-ldd brd-btm-grey'>
                    <div className='mh-pejslsp content-centre'>
                        <p>Tranzacted By:</p>
                    </div>
                    <div className='nbd-peied-ws content-centre'>
                        <p>{task.recipient.sender.firstname} {task.recipient.sender.lastname} ({task.recipient.sender.accountId || task.recipient.sender.userId})</p>
                    </div>
                </div>

                <div className='kdjdjd-ldd brd-btm-grey'>
                    <div className='mh-pejslsp content-centre'>
                        <p>Tranzaction Timestamp:</p>
                    </div>
                    <div className='nbd-peied-ws content-centre'>
                        <p>{task.recipient.timestamp}</p>
                    </div>
                </div>

                {
                    task.state === 3 &&
                    <>
                    <div className='jshskfhk brd-btm-grey'>
                        <p><b>Run Feedback:</b></p>
                        <div className='w-80 centred padd-5 rounded ksjshs'>
                            <p>{task.runFeedback.content}</p>
                            <p>@{task.runFeedback.timestamp}</p>
                        </div>
                    </div>
                    </>
                }
                {
                    task.givenUpCount > 0 &&
                    <>
                    <div className='w-80 centred padd-10 rounded ksjshs kkdjd'>
                        <h3>Available Actions</h3>
                        <Button color='primary' variant='contained' className='force-round-btn ptr' title='Remove task from Zombie Task list' onClick={() => {
                            Utils.resolveProblematicUSSDTask({taskId: task.taskId})
                        }}>
                            Resolve
                        </Button>
                    </div>
                    </>
                }
            </div>
        </div>
        </>
    )
}

export default USSDTaskDetails;