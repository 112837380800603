import React from 'react';
import Assets from '../lib/Assets';


/**
 * 
 * @returns 
 */
const PathNotFound = () => {
    console.log('{PathNotFound}');
    return (
        <div className='not-found aimate__animated animate__fadeIn'>
            <div className='the-content-not-found width-60'>
                <img id='confuded-robot-nvh' className='pointer' src={Assets.icons.confusedRobot} alt='Confused' title='I am really confused' />
                <h2 className='font-wt-900'>404</h2>
                <h3>Sorry! We could not find that page.</h3>
                <p>Try these:</p>
                <div title='Try this page' className='bvv-fhf hfhf-btn no-emph-element centred ptr rounded-circle bdr-w'>
                    <a href='/'>Home</a>
                </div>
            </div>
        </div>
    )
}

export default PathNotFound;