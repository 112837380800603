import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import Utils from '../../lib/Utils';

/**
 * TransactionsStats
 * @returns 
 */
const TransactionsStats = () => {
    console.log('{TransactionsStats}');
    const [stats,setTrzctDailyStats] = useState({
        salesToday: 0,
        totalTrxs: 0,
        salesThisMonth: 0,
        totalTrxsThisMonth: 0,
        salesAll: 0,
        totalTrxsAll: 0,
        tuh: {
            salesToday: 0,
            totalTrxs: 0,
            salesThisMonth: 0,
            totalTrxsThisMonth: 0,
            salesAll: 0,
            totalTrxsAll: 0,
        }
    });
    StateUpdators.setTrzctDailyStats = setTrzctDailyStats;
    return (
        <>
        {
            stats.salesToday && 
            <>
            <br></br>
            <div className='trzct-daily pointer rounded shadow centered animate__animated animate__fadeIn' onClick={() => {
                Utils.goToTopuphomeDaily();
            }} title='View Topuphome Daily Stats'>
                <div id='hfgf-title' className='rounded-top'>
                    <h3>Topuphome Daily</h3>
                </div>
                <div id='jdhdg-body'>
                    <div id='sales-hfhfh'>
                        <div id='hdgdhh-title'>
                            <h2>Sales</h2>
                        </div>
                        <div className='ueeyey-text'>
                            <h2>ZAR{stats.tuh.salesToday}</h2>
                        </div>
                    </div>
                    <div id='trx-jshfh'>
                        <div id='hdgdhh-title'>
                            <h2>Tranzactions</h2>
                        </div>
                        <div id='ueeyey-text-jfhfh' className='ueeyey-text'>
                            <h2>{stats.tuh.totalTrxs}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <br></br>
            <div className='trzct-daily rounded shadow centered animate__animated animate__fadeIn pointer' title='View Topuphome Monthly Stats' onClick={() => {
                Utils.goToTopuphomeMonthly();
            }}>
                <div id='hfgf-title' className='rounded-top'>
                    <h3>Topuphome Monthly</h3>
                </div>
                <div id='jdhdg-body'>
                    <div id='sales-hfhfh'>
                        <div id='hdgdhh-title'>
                            <h2>Sales</h2>
                        </div>
                        <div className='ueeyey-text'>
                            <h2>ZAR{stats.tuh.salesThisMonth}</h2>
                        </div>
                    </div>
                    <div id='trx-jshfh'>
                        <div id='hdgdhh-title'>
                            <h2>Tranzactions</h2>
                        </div>
                        <div id='ueeyey-text-jfhfh' className='ueeyey-text'>
                            <h2>{stats.tuh.totalTrxsThisMonth}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <br></br>
            <div className='trzct-daily rounded shadow centered animate__animated animate__fadeIn pointer' title='View Topuphome All Stats' onClick={() => {
                Utils.goToTopuphomeAll();
            }}>
                <div id='hfgf-title' className='rounded-top'>
                    <h3>Topuphome All</h3>
                </div>
                <div id='jdhdg-body'>
                    <div id='sales-hfhfh'>
                        <div id='hdgdhh-title'>
                            <h2>Sales</h2>
                        </div>
                        <div className='ueeyey-text'>
                            <h2>ZAR{stats.tuh.salesAll}</h2>
                        </div>
                    </div>
                    <div id='trx-jshfh'>
                        <div id='hdgdhh-title'>
                            <h2>Tranzactions</h2>
                        </div>
                        <div id='ueeyey-text-jfhfh' className='ueeyey-text'>
                            <h2>{stats.tuh.totalTrxsAll}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            
            <div className='trzct-daily rounded shadow centered animate__animated animate__fadeIn pointer' onClick={() => {
                Utils.goToTranzactsDaily();
            }} title='View Tranzact Daily Stats'>
                <div id='hfgf-title' className='rounded-top'>
                    <h3>Tranzact Daily</h3>
                </div>
                <div id='jdhdg-body'>
                    <div id='sales-hfhfh'>
                        <div id='hdgdhh-title'>
                            <h2>Sales</h2>
                        </div>
                        <div className='ueeyey-text'>
                            <h2>MWK{stats.salesToday}</h2>
                        </div>
                    </div>
                    <div id='trx-jshfh'>
                        <div id='hdgdhh-title'>
                            <h2>Tranzactions</h2>
                        </div>
                        <div id='ueeyey-text-jfhfh' className='ueeyey-text'>
                            <h2>{stats.totalTrxs}</h2>
                        </div>
                    </div>
                </div>
            </div>
            
            <br></br>
            <div className='trzct-daily rounded shadow centered animate__animated animate__fadeIn pointer' onClick={() => {
                Utils.goToTranzactsMonthly();
            }} title='View Tranzact Monthly Stats'>
                <div id='hfgf-title' className='rounded-top'>
                    <h3>Tranzact Monthly</h3>
                </div>
                <div id='jdhdg-body'>
                    <div id='sales-hfhfh'>
                        <div id='hdgdhh-title'>
                            <h2>Sales</h2>
                        </div>
                        <div className='ueeyey-text'>
                            <h2>MWK{stats.salesThisMonth}</h2>
                        </div>
                    </div>
                    <div id='trx-jshfh'>
                        <div id='hdgdhh-title'>
                            <h2>Tranzactions</h2>
                        </div>
                        <div id='ueeyey-text-jfhfh' className='ueeyey-text'>
                            <h2>{stats.totalTrxsThisMonth}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <br></br>
            <div className='trzct-daily rounded shadow centered animate__animated animate__fadeIn pointer' onClick={() => {
                Utils.goToTranzactsAllTime();
            }} title='View Tranzact All Stats'>
                <div id='hfgf-title' className='rounded-top'>
                    <h3>Tranzact All</h3>
                </div>
                <div id='jdhdg-body'>
                    <div id='sales-hfhfh'>
                        <div id='hdgdhh-title'>
                            <h2>Sales</h2>
                        </div>
                        <div className='ueeyey-text'>
                            <h2>MWK{stats.salesAll}</h2>
                        </div>
                    </div>
                    <div id='trx-jshfh'>
                        <div id='hdgdhh-title'>
                            <h2>Tranzactions</h2>
                        </div>
                        <div id='ueeyey-text-jfhfh' className='ueeyey-text'>
                            <h2>{stats.totalTrxsAll}</h2>
                        </div>
                    </div>
                </div>
            </div>
            </>
        }
        </>
    )
}

export default TransactionsStats;