import React, { useState } from 'react';
import AdminActions from '../../lib/AdminActions';
import AdminActionsFrontends from '../../lib/AdminActionsFrontends';
import StateUpdators from '../../lib/StateUpdators';
import Utils from '../../lib/Utils';

/**
 * 
 * @returns 
 */
const MenuLinks = () => {
    console.log('{MenuLinks}');
    const [show, setShowMenu] = useState(false);
    StateUpdators.setShowMenu = setShowMenu;
    const sessData = Utils.retrieveSession();
    let profile;
    let staffActions;
    if(typeof sessData.profile !== 'undefined' && typeof sessData.staffActions !== 'undefined') {
        profile = sessData.profile;
        staffActions = sessData.staffActions;
    }
    return (
        <>
        {
            show &&
            <div className='hor-meud-ncbvdvdf rounded shadow animate__animated animate__slideInRight'>
                <div className='mcbvbf-mnfhf rounded-top'>
                    <h4>Menu</h4>
                </div>
                {
                    profile && staffActions &&
                    <>
                    <div className='menu-links-mncbcvs centered'>
                        {
                            staffActions.actions.map((action,i) => {
                                return (
                                    <div key={i} className='mfnfkgsgfujfuj-bfg pointer width-90 centered rounded-more'>
                                        <h3 onClick={() => {
                                            const token  = {
                                                action: AdminActionsFrontends[action.action]
                                            }
                                            window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
                                        }} variant='contained' title={AdminActions[action.action]} className='action-buttons-hdgf0jcbcvs'>
                                            {AdminActions[action.action]}
                                        </h3>
                                    </div>
                                )
                            })
                        }
                    </div>
                    </>
                }
            </div>
        }
        </>
    )
}

export default MenuLinks;