import TopuphomeLogoInternals from '../assets/logos/topuphome/Topuhome_v2_512x512_badge_internals.png';
import closeWindow from '../assets/icons/close_window_icon.png';
import warnRed from '../assets/icons/warning_red.png';
import iconMenu from '../assets/icons/horizontal_menu.png';
import horizontalIcon from '../assets/icons/horizontal_menu_x_icon.png';
import statusOk from '../assets/icons/status_ok.png';
import statusNotOk from '../assets/icons/warning_red.png';

const Assets = {
    TopuphomeLogoInternals: TopuphomeLogoInternals,
    closeWindow: closeWindow,
    warnRed: warnRed,
    iconMenu: iconMenu,
    horizontalIcon: horizontalIcon,
    statusOk: statusOk,
    statusNotOk: statusNotOk,
    searchIcon: require('../assets/icons/search_white.png'),
    smallLoader: require('../assets/icons/small_loader.gif'),
    icons: {
        confusedRobot: require('../assets/icons/confused_robot.png'),
        server: require('../assets/icons/server_icon.png'),
        digitalGiftCard: require('../assets/icons/giftcards/tdgc_100.png'),
    }
}

export default Assets;