import React from 'react';
import Assets from '../../lib/Assets';


/**
 * Valiadtion views
 */
const ValidationViews = {
    /**
     * Displayed when validated ok
     * @returns 
     */
    ValidationOk: () => (
        <div className='payment-validated animate__animated animate__fadeIn'>
            <h3>Payment reference validated</h3>
            <img className='pf-sts-ok' src={Assets.statusOk} title='Ok' alt='Ok' />
        </div>
    ),
    /**
     * Validation failed
     * @returns 
     */
    ValidationNotOk: () => (
        <div className='payment-validated animate__animated animate__fadeIn'>
            <h3>Validation failed</h3>
            <img className='pf-sts-ok' src={Assets.statusNotOk} title='X' alt='X' />
        </div>
    )
}

export default ValidationViews;