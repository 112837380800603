import React, { useEffect, useRef } from 'react';
import Utils from '../lib/Utils';
import { Button } from '@mui/material';
import ProgressBar from './tools/ProgressBar';
import Error from './tools/Error';
import TransactionsStats from './tools/TransactionsStats';
import SidePopin from './tools/SidePopin';
import Assets from '../lib/Assets';
import AdminActionsView from './tools/AdminActionsView';

/**
 * The admin home screen
 * @returns 
 */
const AdminHome = () => {
    console.log('{AdminHome}');
    const sessData = Utils.retrieveSession();
    let profile;
    let staffActions = {};
    if(typeof sessData.profile !== 'undefined' && typeof sessData.staffActions !== 'undefined') {
        profile = sessData.profile;
        staffActions = sessData.staffActions;
    }
    const workerRef = useRef();
    staffActions.actions = sessData.staffActions.actions.filter(action => Utils.viewLessActions.indexOf(action.action) < 0);
    useEffect(() => {
        if(!profile && !staffActions) {
            window.location = '/';
        } else {
            Utils.changeBkg();
            Utils.fetchQuickStats();
            workerRef.current = Utils.startWorker({
                jobs: [Utils.checkSessionAndValidate,Utils.fetchQuickStatsQuitely]
            });
        }
        return () => clearInterval(workerRef.current);
    });
    return (
        <>
        {
            profile && staffActions &&
            <>
            <div className='admin-home bcvndbd centered rounded animate__animated animate__fadeIn'>
                <div>
                    <img src={Assets.TopuphomeLogoInternals} id='the-logo' alt='Topuphome Internals' title='Topuphome Internals' className='pointer logo-on-home' />
                </div>
                <div className='the-simple-hdr width-80 shadow centered rounded-more'>
                    <div>
                        <p>Logged in as: {profile.firstname}</p>
                    </div>
                    <div>
                        <p>{Utils.getTimeStamp()}</p>
                    </div>
                    <div className='lgout-btn-home'>
                        <Button className='rounded-force-btn' variant='contained' color='primary' onClick={() => {
                            window.location = '/logout'
                        }}>
                            Logout
                        </Button>
                    </div>
                </div>
                <p className='what-woild-you'>What would you like to do?</p>
                <div className='staff-actions centered'>
                    <AdminActionsView />
                </div>
            </div>
            <div className='quick-stats-home centered rounded width-90 shadow min-height'>
                <p>Quick Stats</p>
                <TransactionsStats />
            </div>
            <ProgressBar />
            <SidePopin />
            <Error />
            </>
        }
        </>
    )
}

export default AdminHome;