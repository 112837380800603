const NeutrifyColors = {
    Primary: {
        Green: '#478247',
        Red: '#ab0606',
    },
    Secondary: {

    },
    Label: {
        OffBlack: '#1f1f21'
    }
}

export default NeutrifyColors;