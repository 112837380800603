import axios from 'axios';
import StateUpdators from './StateUpdators';
import AdminColors from './AdminColors';
import ValidationViews from '../views/tools/ValidationViews';
import Texts from './Texts';
import USSDTaskDetails from '../views/tools/USSDTaskDetails';

/**
 * Utilities
 */
const Utils = {
    WebSocketClientStatus: false,
    wsConnectionId: '',
    //Login input
    loginInput: {},
    trxStatuses: {
        4: 'Successful',
        1: 'Confirmed',
        0: 'Not Confirmed',
        3: 'Paid For',
        5: 'Failed',
        2: 'Pending'
    },
    audiences: {
        user: {}
    },
    /**
     * Rich Text Content
     */
    richTextContent: {},
    audienceLoaded: false,
    /**
     * Session expiry time
     */
    TWELVE_HRS: 1000*60*12,
    TWENTY_SECONDS: 1000*20,
    NeuronF: {
        url: 'https://api.topuphome.co.za/serve/',
        apiKey: 'skey_RJbYQuopZQ8TLJTe6B9kum464S328Sf7YjWtz4yE6vewXFpoAn3VyQUvCuuIa61X',
        USSDServiceKey: {
            apiskey: 'fn1rtkjo77gBSj7Egv70efWNI4AD6L5KaOUgfy4gPlsoFWqsAe46JSDMN9d5LJa3'
        },
        urls: {
            dispatchService: 'https://service-topuphome.techxdynamics.com/',
            //dispatchService: 'http://localhost:5000/',
            WebSocketServer: 'https://service-topuphome-wss.techxdynamics.com/',
            WebSocketServerLocal: 'http://localhost:5555/',
            //WebSocketServer: 'http://localhost:5555/'
        }
    },
    callingFirstTime: true,
    /**
     * Actions that do not have their own views
     */
    viewLessActions: ['DeleteTranzactMerchant'],
    /**
     * Fetch the USSD Websocket client connection Id
     */
    fetchUSSDClientConnectionId: async () => {
        console.log('[Utils.fetchUSSDClientConnectionId]');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchUSSDClientConnectionId',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            if(results.data.status) {
                Utils.wsConnectionId = results.data.connectionId;
                return Utils.wsConnectionId;
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Resolve a stuck task by removing it from the Zombie and Given Up Tasks lists
     * @param {object} info The info object
     */
    resolveProblematicUSSDTask: async (info) => {
        console.log('[Utils.resolveProblematicUSSDTask]');
        Utils.showProgressBar('Resolving...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'resolveProblematicUSSDTask',
                    data: {
                        ...info,
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                Utils.showSidePopinSuccess({msg: results.data.message});
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch a given ussd task details
     * @param {object} info The info object with the `taskId` prop (number|integer). 
     */
    fetchUSSDTaskDetails: async (info) => {
        //console.log('[Utils.fetchUSSDTaskDetails]: info=',info);
        console.log('[Utils.fetchUSSDTaskDetails]');
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchUSSDTaskDetails',
                    data: {
                        ...info,
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                Utils.showActionModal({Content: () => <USSDTaskDetails task={results.data.task} />});
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch USSD Tasks
     */
    fetchUSSDTasks: async () => {
        console.log('[Utils.fetchUSSDTasks]');
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchUSSDTasks',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            console.log('R=',results);
            if(results.data.status) {
                StateUpdators.setUSSDTasks(results.data.tasks);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Listsen to click event on the login form page
     * @param {object} info The info object
     */
    setClickEventListner: (info) => {
        console.log('utils.setClickEventListner');
        window.onkeydown = (e) => {
            if(e.key === 'Enter') {
                if(typeof Utils.loginInput.username !== 'undefined' && typeof Utils.loginInput.password !== 'undefined') {
                    if(Utils.loginInput.username && Utils.loginInput.password) {
                        if(typeof info.returnURL !== 'undefined') {
                            Utils.loginInput.returnURL = info.returnURL;
                        }
                        Utils.login({...Utils.loginInput});
                    }
                }
            }
        }
    },
    /**
     * 
     * @param {object} info The `info` object with the required info 
     */
    saveNewUserDigitalGiftCardBalance: async (info) => {
        console.log('[Utils.saveNewUserDigitalGiftCardBalance]');
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'updateUserDigitalGiftCard',
                    data: {
                        ...info,
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                document.getElementById(`card-${info.cardId}`).innerText = results.data.newBalanceDisplay;
                Utils.showSidePopinSuccess(Texts.success.updateDGC);
                Utils.hideActionModal();
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * User digital gift cards
     */
    fetchUserDigitalGiftCards: async () => {
        console.log('[Utils.fetchUserDigitalGiftCards]');
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchUserDigitalGiftCards',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                StateUpdators.setGigitalGiftCards(results.data.cards);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Show a popup with content.
     * @param {object} info The `info` object with the `Content` React component object to render.
     */
    showActionModal: (info) => {
        const View = info.Content;
        StateUpdators.setCActionMdoalInfo({show: true, View: () => <View />})
    },
    /**
     * Hide the popup the ActionModal
     */
    hideActionModal: () => {
        StateUpdators.setCActionMdoalInfo({show: false, View: () => <></>})
    },
    /**
     * Check the USSD service status. This is the results of checking the USSD Client
     */
    checkUSSDServiceStatus: () => {
        return new Promise((resolve) => {
            const FOUR_SECONDS = 4000;
            setTimeout(async () => {
                const sessData = Utils.retrieveSession();
                const payload = {
                    endpoint: 'Admin',
                    payload: {
                        action: 'checkUSSDServiceStatus',
                        data: {
                            accessToken: sessData.accessToken,
                            csrfToken: sessData.csrfToken
                        }
                    }
                }
                let results = await axios.post(Utils.NeuronF.url,{
                    data: payload
                }, {
                    headers: {
                        TopuphomeSKey: Utils.NeuronF.apiKey
                    }
                });
                results = results.data;
                if(!results.data.status) {
                    Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
                    resolve(results.data);
                }
                resolve(results.data)
            },FOUR_SECONDS)
        });
    },
    /**
     * Check system statuses
     * @param {object} services The services object (array)
     */
    checkSystemsStstus: async ({services}) => {
        console.log('[Utils.checkSystemsStstus]');
        //console.log('Utils.wsConnectionId=',Utils.wsConnectionId);
        if(!Utils.wsConnectionId) {
            Utils.wsConnectionId = (await Utils.fetchUSSDClientConnectionId());
        }
        //console.log('Utils.wsConnectionId=',Utils.wsConnectionId);
        const _services = {
            /**
             * Check that the DispatchService is online
             */
            DispatchService: async () => {
                console.log('[Utils.checkSystemsStstus.DispatchService]');
                let results = (await axios.get(Utils.NeuronF.urls.dispatchService)).data;
                results = results.data??results;
                return {
                    status: results.status === 'online' ? 'Online' : 'Offline', 
                    error: results.error??false,
                    msg: results.message??''
                };
            },
            /**
             * Check that the Websocket server is online
             */
            WebSocketServer: async () => {
                console.log('[Utils.checkSystemsStstus.WebSocketServer]');
                const results = (await axios.get(`${Utils.NeuronF.urls.WebSocketServer}ping`)).data;
                return {
                    status: results.status === 'OK' ? 'Online': 'Offline',
                    error: results.error??false,
                    msg: results.message??''
                };
            },
            /**
             * Check that the WebSocketClient is online
             */
            WebSocketClient: async () => {
                console.log('[Utils.checkSystemsStstus.WebSocketClient]');
                const _data = {
                    endpoint: 'PlatformRequests',
                    payload: {
                        action: 'runTUHWSSTask',
                        data: {
                            command: 'CheckUSSDClient',
                            connectionId: Utils.wsConnectionId
                        }
                    }
                };
                let results = (await axios.post(`${Utils.NeuronF.urls.WebSocketServer}service-2`,
                {..._data},
                {
                    headers: {...Utils.NeuronF.USSDServiceKey}
                })).data;
                results = results.data??results;
                Utils.WebSocketClientStatus = results.status;
                return {
                    status: results.status === 'OK' ||  results.status ? 'Online': 'Offline',
                    error: results.error,
                    msg: results.message || results.msg
                };
            },
            /**
             * Check that the USSDService is online
             */
            USSDService: async () => {
                console.log('[Utils.checkSystemsStstus.USSDService]');
                const results = await Utils.checkUSSDServiceStatus();
                //console.log('Utils.WebSocketClientStatus=',Utils.WebSocketClientStatus);
                return {
                    status: results.status && Utils.WebSocketClientStatus ? 'Online' : 'Offline',
                    error: results.error??'',
                    msg: results.msg??results.message
                }
            }
        };
        services.forEach(service => {
            (async() => {
                try {
                    const results = await _services[service.name]();
                    service.stateUpdator({
                        ...results,
                        name: service.name
                    });
                } catch (error) {
                    console.log('Error: ',error);
                    service.stateUpdator({
                        status: false,
                        msg: error.message,
                        name: service.name,
                        error: 'fatalError'
                    });
                }
            })()
        })
    },
    /**
     * Fetch inventory from the backend
     */
    fetchInventories: async () => {
        console.log('[Utils.fetchInventories]');
        Utils.showProgressBar();
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchInventories',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                StateUpdators.setServices(results.data.services);
            } else {
                Utils.showSidePopinError(results.data.message || results.data.errorMessage, results.data.error || false);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch order history for a given service
     * @param {object} info The info object
     */
    fetchInventoryOrderHistory: async (info) => {
        console.log('[Utils.fetchInventoryOrderHistory]: info=',info);
    },
    /**
     * Set user audience for later use
     * @param {object} info The info object woth the user type and the user object to set
     */
    setAudienceUser: (info) => {
        console.log('[Utils.setAudienceUser]');
        Utils.audiences.user = info.user;
    },
    /**
     * Encode a string to base 64
     * @param {string} str The string to encode to the binary 64 encoding
     * @returns The encoded string
     */
    base64Encode: (str) => {
        return btoa(encodeURIComponent(str));
    },
    /**
     * Decode a string from base 64 to normal string
     * @param {string} str The string to decode from base64 to utf8
     * @returns The encoded string
     */
    base64Decode: (str) => {
        return decodeURIComponent(window.atob(str));
    },
    /**
     * Remove spaces in a given string
     * @param {string} input The input to remove spaces
     */
    removeSpaces: (input) => {
        console.log('[Utils.removeSpaces]');
        return input.replaceAll(' ','');
    },
    /**
     * Returs today's date in the format: YYYY-MM-DD
     */
    getDate: () => {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return `${year}-${month}-${day}`;
    },
    /**
     * Returs current time in the format: hh-mm-ss
     * @return {string} The time
     */
    getTime: () => {
        return Date().split(' ')[4];
    },
    /**
     * Format number
     * @param {string} seperator The separator
     * @param {number} decimalPlaces How many decimals to
     */
    numberFormat: (seperator = ',', decimalPlaces = 2) => {
        console.log('[numberFormat]: separator=',seperator,' decimalPlaces=',decimalPlaces);
    },
    /**
    * Retuns time stamp in the format yyyy-MM-dd hh:mm:ss
    * @return {string} The timestamp
    */
    getTimeStamp: () => {
        return `${Utils.getDate()} ${Utils.getTime()}`;
    },
    /**
     * Take the user to their default screen based on their type
     */
    defaultUserScreen: () => {
        console.log('[Utils.defaultUserScreen]');
        const session = localStorage.getItem('TuhSession');
        window.location = `/${session.userTypeDefaultScreen}`;
    },
    /**
     * Take us directly to the daily tranzact transactions
     */
    goToTranzactsDaily: () => {
        console.log('[Utils.goToTranzactsDaily]');
        const token = {
            action: 'viewTrzctTrxs'
        };
        window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
        
    },
    /**
     * View Topuphome daily transactons
     */
    goToTopuphomeDaily: () => {
        console.log('[Utils.goToTopuphomeDaily]');
        const token = {
            action: 'vewTuhTrxs'
        };
        window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
    },
    /**
     * View Topuphome monthly transactons
     */
    goToTopuphomeMonthly: () => {
        console.log('[Utils.goToTopuphomeMonthly]');
        const token = {
            action: 'vewTuhTrxs',
            filter: 'period',
            period: 'thisMonth'
        };
        window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
    },
    /**
     * View Topuphome all transactons
     */
    goToTopuphomeAll: () => {
        console.log('[Utils.goToTopuphomeAll]');
        const token = {
            action: 'vewTuhTrxs',
            filter: 'period',
            period: 'allTime'
        };
        window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
    },
    /**
     * Take us directly to the monthly (this month) tranzact transactions
     */
    goToTranzactsMonthly: () => {
        console.log('[Utils.goToTranzactsMonthly]');
        const token = {
            action: 'viewTrzctTrxs',
            filter: 'period',
            period: 'thisMonth'
        };
        window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
    },
    /**
     * Take us directly to the all-time tranzact transactions
     */
    goToTranzactsAllTime: () => {
        console.log('Utils.goToTranzactsAllTime');
        const token = {
            action: 'viewTrzctTrxs',
            filter: 'period',
            period: 'allTime'
        };
        window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
    },
    /**
     * Lets go to a page
     * @param {string} link The link to open the page to
     */
    open: (link) => {
        console.log('[Utils.open]');
        window.open(link);
    },
    /**
     * Authenticate this user
     * @param {object} info The info object with the creds
     */
    login: async (info) => {
        console.log('[Utils.login]');
        Utils.showProgressBar('Authenticating...');
        try {
            const payload = {
                endpoint: 'AdminAuth',
                payload: {
                    action: 'login',
                    data: {
                        ...info
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                Utils.showSidePopinSuccess('Successfully logged in');
                const sessionData = results.data;
                delete sessionData.sessionUpdated;
                delete sessionData.status;
                sessionData.loggedIn = true;
                sessionData.loggedInAt = Date.now();
                Utils.storeSession(sessionData);
                setTimeout(() => {
                    if(info.returnURL) {
                        window.location = info.returnURL;
                    } else {
                        StateUpdators.setIsLoggedIn(true);
                    }
                },3000);
            } else {
                Utils.showSidePopinError(results.data.message || results.data.errorMessage);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Logout user
     */
    logoutUser: async () => {
        console.log('[Utils.logoutUser]');
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'logout',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                Utils.showSidePopinSuccess('Successfully logged out');
                Utils.deleteSessionData();
                setTimeout(() => {
                    window.location = './';
                },3000);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch current merchants
     */
    fetchTranzactMerchants: async () => {
        console.log('[Utils.fetchTranzactMerchants]');
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchTranzactMerchants',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                StateUpdators.setMerchantsForSelect(results.data.merchants);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch current Topuphome agents
     */
    fetchTopuphomeAgents: async () => {
        console.log('[Utils.fetchTopuphomeAgents]');
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchTopuphomeAgents',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                StateUpdators.setAgentsForSelect(results.data.agents);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch current merchants
     */
    fetchTopuphomeUsers: async () => {
        console.log('[Utils.fetchTopuphomeUsers]');
    },
    /**
     * Set the page title. Call this method on every page to set the page title displayed in the browser.
     * @param {object} info The info object with the page title to set
     */
    setPageTitle: (info) => {
        document.title = `Topuphome Internals | ${info.title}`;
    },
    /**
     * Removes if an input number has the leading zero recursively.
     * Phone Numbers should not have a leading zero from the user.
     * Assumes that the function will be called on non-empty string
     * @param inputString = (string:number) a cell phone number
     */
    stripLeadingZero: (inputString) => {
        let stringTemp = '';
        if ((inputString && inputString[0] === '0')
            || (inputString && inputString[0] === 0)) {
            for (var char = 1; char < inputString.length; char++) {
                stringTemp += inputString[char];
            }
            stringTemp = Utils.stripLeadingZero(stringTemp);
        }
        else {
            stringTemp = inputString;
        }
        return Utils.stripLeadingPlusChar(stringTemp.replace(/\s+/g, ''));
    },
    /**
     * Similar to the above one but targets the '+' char if the user enters it
     * @param {string|number} inputString A cell phone number
     */
    stripLeadingPlusChar: (inputString) => {
        let stringTemp = '';
        if (inputString && inputString[0] === '+') {
            for (var char = 1; char < inputString.length; char++) {
                stringTemp += inputString[char];
            }
            stringTemp = Utils.stripLeadingZero(stringTemp);
        }
        else {
            stringTemp = inputString;
        }
        return stringTemp;
    },
    /**
     * Submit the merchant info
     * @param {object} info The info object
     */
    submitMerchantInfo: async (info) => {
        console.log('[Utils.submitMerchantInfo]');
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        const DEFAULT_COUNTRY = 'Malawi';//will have to be dynamic
        const DEFAULT_SERVICE = {
            service_8547: {
                name: 'Airtime',
                id: 8547,
                balance: 0.0
            }
        }
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'addMerchant',
                    data: {
                        ...info,
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        country: DEFAULT_COUNTRY,
                        services: {
                            ...DEFAULT_SERVICE
                        }
                    }
                }
            }
            console.log('payload: ',payload);
            //return;
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                Utils.showSidePopinSuccess('Merchant successfully added');
                setTimeout(() => {
                    const token = {
                        action: 'viewTrzctMerchant',
                        merchantId: results.data.merchantId,
                        newMerchant: true
                    };
                    window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
                },3000);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Load merchants info. Works for both to fetch a single merchant or many merchants
     * @param {object} info The info object
     */
    loadMerchants: async (info) => {
        console.log('[Utils.loadMerchants]');
        if(!info.loadAtAll) return;//whether to execute the loading of merchats
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        const action = info.loadOne ? 'fetchMerchant' : 'fetchTranzactMerchants';
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: action,
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        accountId: parseInt(info.merchantId),
                        serviceId: 8547
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                StateUpdators.setMerchants(typeof results.data.accountInfo !== 'undefined' ? [results.data.accountInfo] : results.data.merchants);
                StateUpdators.setMerchatDataStatus(true);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Load agents info. Works for both to fetch a single agent or many
     * @param {object} info The info object
     */
    loadAgents: async (info) => {
        console.log('[Utils.loadAgents]');
        if(!info.loadAtAll) return;//whether to request the loading of agents/agent
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        const action = info.loadOne ? 'fetchTopuphomeAgent' : 'fetchTopuphomeAgents';
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: action,
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        accountId: parseInt(info.agentId),
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                StateUpdators.setAgents(typeof results.data.accountInfo !== 'undefined' ? [results.data.accountInfo] : results.data.agents);
                StateUpdators.setAgentDataStatus(true);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
     /**
     * Load merchants balances
     * @param {object} info The info object
     */
    loadMerchantBalances: async (info) => {
        console.log('[Utils.loadMerchantBalances]');
        if(!info.loadBalances) return;
        Utils.showProgressBar('Please wait...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'loadMerchantBalances',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                StateUpdators.setMerchantBalances(results.data.merchants);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Activate an order for a new merchant to start selling from their account
     * @param {object} info The infor object to use to activate the order
     */
    activateOrder: async (info) => {
        console.log('[Utils.activateOrder]: info',info);
        Utils.showProgressBar('Processing...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'activateOrder',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                Utils.showSidePopinSuccess('Order successfully activated.');
                StateUpdators.setConfirmationInfo({show: false});
                setTimeout(() => {
                    const token = {
                        action: 'viewTrzctMerchant',
                        merchantId: info.merchantId
                    };
                    window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
                },3000);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch order requests
     * @param {object} info The info object
     */
    fetchOrderRequests: async (info) => {
        console.log('[Utils.fetchOrderRequests]');
        Utils.showProgressBar('Processing...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchOrderRequests',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                if(results.data.requestId || (results.data.orderRequests && results.data.orderRequests.length > 0)) {
                    StateUpdators.setDisplayRequestData({
                        available: true,
                        ...results.data
                    });
                } else {
                    StateUpdators.setDisplayRequestData({available: false});
                }
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Place a new order fo
     * @param {object} info The info object
     */
    placeNewOrderFromRequest: async (info) => {
        console.log('[Utils.placeNewOrderFromRequest]');
        Utils.showProgressBar('Processing...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'placeNewOrderFromRequest',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            console.log('Results: ',results);
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                Utils.showSidePopinSuccess('Order successfully processed.');
                StateUpdators.setConfirmationInfo({show: false});
                setTimeout(() => {
                    const token = {
                        action: 'viewTrzctMerchant',
                        merchantId: info.merchantId
                    };
                    window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
                },3000);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Place a new tranzact order for a merchant
     * @param {object} info The info object
     */
    placeNewOrder: async (info) => {
        console.log('[Utils.placeNewOrder]: info=',info);
        Utils.showProgressBar('Processing...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'placeNewOrder',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                Utils.showSidePopinSuccess('Order successfully processed.');
                StateUpdators.setConfirmationInfo({show: false});
                setTimeout(() => {
                    const token = {
                        action: 'viewTrzctMerchant',
                        merchantId: info.merchantId
                    };
                    window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
                },3000);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Place a new tranzact order for a merchant
     * @param {object} info The info object
     */
    placeNewTopuphomeAgentOrder: async (info) => {
        console.log('[Utils.placeNewTopuphomeAgentOrder]');
        Utils.showProgressBar('Processing...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'placeNewTopuphomeAgentOrder',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info,
                        agentId: parseInt(info.agentId),
                        accountId: parseInt(info.agentId),
                        orderAmount: parseFloat(info.orderAmount)
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                Utils.showSidePopinSuccess('Order successfully processed.');
                StateUpdators.setConfirmationInfo({show: false});
                setTimeout(() => {
                    const token = {
                        action: 'topuphomeAgents',
                        agentId: info.agentId
                    };
                    window.location = `/adminAction/${Utils.base64Encode(JSON.stringify(token))}`;
                },3000);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Check session
     */
    checkSession: async () => {
        console.log('[Utils.AndVaildate]');
        const sessionData = Utils.retrieveSession();
        if(sessionData) {
            if(sessionData.loggedIn && ((Date.now()-sessionData.loggedInAt) < Utils.TWELVE_HRS)) {
                await Utils.validateSession({...sessionData});
            } else {
                StateUpdators.setIsLoggedIn(false);
            }
        }
    },
    /**
     * Validate session data
     * @param {object} sessData The session data to validate
     */
    validateSession: async (sessData) => {
        console.log('[Utils.validateSession]: sessData=',sessData);
        Utils.showProgressBar('Please wait...');
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'checkSession',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                StateUpdators.setIsLoggedIn(true);
            } else {
                Utils.showSidePopinError('Please login first to continue...');
                setTimeout(() => {
                    StateUpdators.setIsLoggedIn(false);
                },3000);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Change the background of a view, the root especially
     */
    changeBkg: () => {
        console.log('[changeBkg]');
        document.getElementById('root').classList.add('root-on-admin-home');
    },
    /**
     * Flip an object. For example, flib {x: 1, y: 2} to {1: 'x', 2: 'y'}
     * @param {object} objct The object to flip
     */
    flipObject: (objct) => {
        console.log('[Utils.flipObject]');
        return Object.fromEntries(Object.entries(objct).map(([key, value]) => [value, key]));
    },
    /**
     * Store session data
     * @param {object} data The data to save
     */
    storeSession: (data) => {
        console.log('[Utils.storeSession]');
        data = JSON.stringify(data);
        localStorage.setItem('tuhAdminSession', data);
    },
    /**
     * Retrieve session data
     */
    retrieveSession: () => {
        console.log('[Utils.retrieveSession]');
        const sessionData = localStorage.getItem('tuhAdminSession');
        if(sessionData) {
            return JSON.parse(sessionData);
        }
        return false;
    },
    /**
     * Delete the session data, since user is logged out
     */
    deleteSessionData: () => {
        localStorage.removeItem('tuhAdminSession');
    },
    /**
     * Show a fatal error
     * @param {string} msg The error message
     */
    showFatalError: (msg) => {
        console.log('[Utils.hideFatalError]');
        StateUpdators.setShowFatalError({
            error: true,
            msg: msg
        })
    },
    /**
     * Hide a fatal error
     */
    hideFatalError: () => {
        console.log('[Utils.hideFatalError]');
        StateUpdators.setShowFatalError({
            error: false
        });
    },
    /**
     * Show the side popin with a message
     * @param {string} msg The message to show
     */
    showSidePopinSuccess: (msg = 'A message was not set') => {
        console.log('[Utils.showSidePopinSuccess]');
        StateUpdators.setShow(true);
        StateUpdators.setShowMode('animate__fadeInRight');
        StateUpdators.setVisualConfigs({
            text: msg,
            color: AdminColors.Primary.Green
        });
    },
    /**
     * Show the side popin with a message
     * @param {string} msg The message to show
     * @param {string|boolean} error Whether there is an error or not
     * @param {string|boolean} returnURL The return URL, if set
     */
    showSidePopinError: (msg = 'A message was not set', error = false, returnURL = false) => {
        console.log('[Utils.showSidePopinError]: error=',error);
        StateUpdators.setShow(true);
        StateUpdators.setShowMode('animate__fadeInRight');
        StateUpdators.setVisualConfigs({
            text: msg,
            color: AdminColors.Primary.Red
        });
        if(error) {
            if(error === 'notAuthorized' || error === 'sessionHasExpired') {
                returnURL = Utils.base64Encode(window.location.href);
                setTimeout(() => {
                    window.location = `/loginFirst/${returnURL}`;
                },3000);
            }
        }
    },
    /**
     * Show the progress bar
     * @param {string} msg The message to show while processing
     */
    showProgressBar: (msg = 'Processing...') => {
        console.log('[Utils.showProgressBar]');
        StateUpdators.setProgressBarConfigs({
            show: true,
            msg: msg
        });
    },
    /**
     * Hide the progress bar
     */
    hideProgressBar: () => {
        console.log('[Utils.hideProgressBar]');
        StateUpdators.setProgressBarConfigs({
            show: false,
            msg: ''
        });
    },
    /**
     * Fetch unsettled orders for a given merchant
     * @param {object} info The info object
     * @returns 
     */
    fetchCurrentUnSetlledOrder: async (info) => {
        console.log('[Utils.fetchCurrentUnSetlledOrder]');
        if(!info.merchantId) return;
        Utils.showProgressBar('Fetching orders...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchCurrentUnSetlledOrder',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                StateUpdators.setUnSettledOrder({
                    available: results.data.orders.length > 0,
                    orders: results.data.orders.length > 0 ? results.data.orders : []
                })
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch unsettled orders for a given agent/reseller
     * @param {object} info The info object
     * @returns 
     */
    fetchCurrentUnSetlledAgentOrders: async (info) => {
        console.log('[Utils.fetchCurrentUnSetlledAgentOrders]');
        if(!info.agentId) return;
        Utils.showProgressBar('Fetching orders...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchCurrentUnSetlledAgentOrders',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                StateUpdators.setUnSettledAgentOrders({
                    available: results.data.orders.length > 0,
                    orders: results.data.orders.length > 0 ? results.data.orders : []
                })
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Send request to settle an order for a given merchant 
     * @param {object} info Thebinfo object
     */
    settleOrder: async (info) => {
        console.log('[Utils.settleOrder]');
        Utils.showProgressBar('Processing...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'settleOrder',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                Utils.showSidePopinSuccess('Order successfully settled.');
                StateUpdators.setConfirmationInfo({show: false});
                setTimeout(() => {
                    window.location = `/home`;
                },3000);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Send request to settle an order for a given agent/reseller 
     * @param {object} info Thebinfo object
     */
    settleAgentOrder: async (info) => {
        console.log('[Utils.settleAgentOrder]');
        Utils.showProgressBar('Processing...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'settleResellerOrder',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                Utils.showSidePopinSuccess('Order successfully settled.');
                StateUpdators.setConfirmationInfo({show: false});
                setTimeout(() => {
                    window.location = `/home`;
                },3000);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch quick statistics
     */
    fetchQuickStats: async () => {
        console.log('[Utils.fetchQuickStats]');
        Utils.showProgressBar('Processing...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchQuickStats',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                StateUpdators.setTrzctDailyStats({
                    salesToday: results.data.salesToday,
                    totalTrxs: results.data.totalTrxs,
                    salesThisMonth: results.data.salesThisMonth,
                    totalTrxsThisMonth: results.data.totalTrxsThisMonth,
                    salesAll: results.data.salesAll,
                    totalTrxsAll: results.data.totalTrxsAll,
                    tuh: results.data.tuh
                });
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch quick stats in the background
     */
    fetchQuickStatsQuitely: async () => {
        console.log('[Utils.fetchQuickStatsQuitely]');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchQuickStats',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            results = results.data;
            if(results.data.status) {
                StateUpdators.setTrzctDailyStats({
                    salesToday: results.data.salesToday,
                    totalTrxs: results.data.totalTrxs,
                    salesThisMonth: results.data.salesThisMonth,
                    totalTrxsThisMonth: results.data.totalTrxsThisMonth,
                    salesAll: results.data.salesAll,
                    totalTrxsAll: results.data.totalTrxsAll,
                    tuh: results.data.tuh
                });
            } else {
                console.log('There was an error while fetching quick stats: ',typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage);
            }
        } catch (error) {
            console.log('There was an error while fetching quick stats: ', error.message);
        }
    },
    /**
     * Run a given jobs repeatedly. Runs the job every 20 seconds.
     * @param {object} config The config object containing the jobs to run
     * @returns 
     */
    startWorker: (config) => {
        console.log('[Utils.startWorker]');
        return setInterval(() => {
            config.jobs.forEach(job => {
                job();
            });
        },Utils.TWENTY_SECONDS);
    },
    /**
     * Check the login session
     */
    checkSessionAndValidate: async () => {
        console.log('[Utils.checkSessionAndValidate]');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'checkSession',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            results = results.data;
            if(results.data.status) {
                console.log('Session was checked and updated successfully');
            } else {
                console.log('There was an error while checking the session: ',typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage);
            }
        } catch (error) {
            console.log('There was an error while check the session: ', error.message);
        }
    },
    /**
     * Fetch tranzact transactions based on some filters.
     * @param {object} filters THe filter object
     */
    fetchTranzactTransactions: async (filters) => {
        console.log('[Utils.fetchTranzactTransactions]');
        if(!Utils.callingFirstTime) {
            Utils.showProgressBar('Fetching...');
        }
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchTranzactTransactions',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...filters
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                Utils[results.data.trxViewType](results.data.transactions);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch Topuphome transactions based on some filters.
     * @param {object} filters THe filter object
     */
    fetchTopuphomeTransactions: async (filters) => {
        console.log('[Utils.fetchTopuphomeTransactions]');
        Utils.showProgressBar('Fetching...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchTopuphomeTransactions',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...filters
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                Utils.callingFirstTime = false;
                Utils[results.data.trxViewType](results.data.transactions);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Display tranzact transactions based on a selected merchat, for all transaction statuses
     * @param {array} transactions The transactions array
     */
    merchantTransactionsView: (transactions) => {
        console.log('[Utils.merchantTransactionsView]');
        let View;
        if(transactions.length < 1) {
            View = () => (<h3>There are no transactions for this Merchant.</h3>)
        } else {
            View = () => (
                <>
                <div className='gfgfg-total-nfhfh rounded-top'>
                    <h4>Total Sales: <b className='total-ncbcd'>K{Number.parseFloat(transactions.map(transaction => transaction.amount).reduce((x,y) => x+y)).toFixed(2)}</b>, Transactions: <b className='total-ncbcd-trsbs'>{transactions.length}</b></h4>
                </div>
                <div className='fhdhfg-tablencgd'>
                    <table>
                        <thead>
                            <tr>
                                <th>TrxId</th>
                                <th>Amount</th>
                                <th>Recipient</th>
                                <th>Timestamp</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                transactions.map(transaction => (
                                    <tr key={transaction.trxId}>
                                        <td>{transaction.trxId}</td>
                                        <td><b className='total-ncbcd-mfj'>K{transaction.amount}</b></td>
                                        <td>{transaction.recipient}</td>
                                        <td><b className='bcvdh-date'>{transaction.date}</b></td>
                                        <td>{transaction.status}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                </>
            )
        }
        StateUpdators.setTransactionsView({
            ready: true,
            View: () => (<View />)
        })
    },
    /**
     * Display tranzact transactions based on a selected status, for all merchants
     * @param {array} transactions The transactions array
     */
    allTransactionsView: (transactions) => {
        console.log('[Utils.allTransactionsView]');
        let View;
        if(transactions.length < 1) {
            View = () => (<h3>There are no transactions for the selected filter.</h3>)
        } else {
            View = () => (
                <>
                <div className='gfgfg-total-nfhfh rounded-top'>
                    <h4>Total Sales: <b className='total-ncbcd'>K{Number.parseFloat(transactions.map(transaction => transaction.amount).reduce((x,y) => x+y)).toFixed(2)}</b>, Transactions: <b className='total-ncbcd-trsbs'>{transactions.length}</b></h4>
                </div>
                <div className='fhdhfg-tablencgd'>
                    <table>
                        <thead>
                            <tr>
                                <th>TrxId</th>
                                <th>MerchantId</th>
                                <th>Amount</th>
                                <th>Recipient</th>
                                <th>Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                transactions.map(transaction => (
                                    <tr title='Click to view more info about this transaction' className='trx-hook pointer' key={transaction.trxId} onClick={(e) => {
                                        for (const val of Object.values(document.getElementsByClassName('trx-hook'))) {
                                            val.classList.remove('trx-clicked');
                                        }
                                        e.currentTarget.classList.add('trx-clicked');
                                        Utils.showTranzactMerchant({merchantId: transaction.merchantId, trxId: transaction.trxId});
                                    }}>
                                        <td>{transaction.trxId}</td>
                                        <td>{transaction.merchantId}</td>
                                        <td><b className='total-ncbcd-mfj'>K{transaction.amount}</b></td>
                                        <td>{transaction.recipient}</td>
                                        <td><b className='bcvdh-date'>{transaction.date}</b></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                </>
            )
        }
        StateUpdators.setTransactionsView({
            ready: true,
            View: () => (<View />)
        })
    },
    /**
     * 
     * @param {object} info The info obejct
     */
    showTranzactMerchant: async (info) => {
        console.log('[Utils.showTranzactMerchant]');
        Utils.showProgressBar();
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchMerchant',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        accountId: parseInt(info.merchantId),
                        serviceId: 8547//must be dynamic
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                StateUpdators.setShowSingleMerchantOnTransactionsView({
                    show: true,
                    data: {
                        ...results.data.accountInfo,
                        trxId: info.trxId
                    }
                })
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch transact orders based on a filter
     * @param {object} filters The filters object
     */
    fetchTranzactOrders: async (filters) => {
        console.log('[Utils.fetchTranzactOrders]: filters=',filters);
        if(!Utils.callingFirstTime) {
            Utils.showProgressBar('Fetching...');
        }
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchTranzactOrders',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...filters
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                Utils.callingFirstTime = false;
                Utils[results.data.trxViewType](results.data.orders);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Display tranzact orders based on a selected merchats, for all settlement states
     * @param {array} orders The orders array
     */
    merchantTransacOrdersView: (orders) => {
        console.log('[Utils.merchantTransacOrdersView]');
        let View;
        if(orders.length < 1) {
            View = () => (<h3>There are no active orders for this Merchant.</h3>)
        } else {
            View = () => (
                <>
                <div className='gfgfg-total-nfhfh rounded bcvdhdg'>
                    <h4>Orders Total: <b className='total-ncbcd'>K{orders.map(order => order.amount).reduce((x,y) => x+y)}</b>, <br></br>Orders: <b className='total-ncbcd-trsbs'>{orders.length}</b></h4>
                </div>
                <div className='gfgfg-total-nfhfh rounded bcvdhdg settled-bcvsbsv'>
                    <h4>Settled Orders Total: <b className='total-ncbcd'>K{orders.filter(order => order.settled === 1).length > 0 ? orders.filter(order => order.settled === 1).map(order => order.amount).reduce((x,y) => x+y) : 0}</b>,<br></br>Settled Orders: <b className='total-ncbcd-trsbs'>{orders.filter(order => order.settled === 1).length > 0 ? orders.filter(order => order.settled === 1).length : 0}</b></h4>
                </div>
                <div className='gfgfg-total-nfhfh rounded bcvdhdg un-settled-bcvsbsv'>
                    <h4>Un Settled Orders Total: <b className='total-ncbcd'>K{orders.filter(order => order.settled === 0).length > 0 ? orders.filter(order => order.settled === 0).map(order => order.amount).reduce((x,y) => x+y) : 0}</b>, <br></br>Un Settled Orders: <b className='total-ncbcd-trsbs'>{orders.filter(order => order.settled === 0).length > 0 ? orders.filter(order => order.settled === 0).length : 0}</b></h4>
                </div>
                <div className='fhdhfg-tablencgd'>
                    <table>
                        <thead>
                            <tr>
                                <th>OrderId</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Settled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders.map(order => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td><b className='total-ncbcd-mfj'>K{order.amount}</b></td>
                                        <td><b className='bcvdh-date'>{order.date}</b></td>
                                        <td className={order.settled ? 'settled-td0ncbvb':'un-settled-td0ncbvb'}>{order.settled ? 'YES':'NO'}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                </>
            )
        }
        StateUpdators.setTransactOrdersView({
            ready: true,
            View: () => (<View />)
        })
    },
    /**
     * Display tranzact orders based on a selected status, for all merchants
     * @param {array} orders The orders array
     */
    allTranzactOrdersView: (orders) => {
        console.log('[Utils.allTranzactOrdersView]');
        let View;
        if(orders.length < 1) {
            View = () => (<h3>There are no orders for the selected filter.</h3>)
        } else {
            View = () => (
                <>
                <div className='gfgfg-total-nfhfh rounded-top'>
                    <h4>Orders Total: <b className='total-ncbcd'>K{orders.map(order => order.amount).reduce((x,y) => x+y)}</b>, Orders: <b className='total-ncbcd-trsbs'>{orders.length}</b></h4>
                </div>
                <div className='fhdhfg-tablencgd'>
                    <table>
                        <thead>
                            <tr>
                                <th>OrderId</th>
                                <th>MerchantId</th>
                                <th>Amount</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders.map(order => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.merchantId}</td>
                                        <td><b className='total-ncbcd-mfj'>K{order.amount}</b></td>
                                        <td><b className='bcvdh-date'>{order.date}</b></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                </>
            )
        }
        StateUpdators.setTransactOrdersView({
            ready: true,
            View: () => (<View />)
        })
    },
    /**
     * Display tranzact orders based on a selected status, for all merchants
     * @param {array} orders The orders array
     */
    allTranzactOrdersViewAll: (orders) => {
        console.log('[Utils.allTranzactOrdersViewAll]');
        let View;
        if(orders.length < 1) {
            View = () => (<h3>There are no active orders for this filter.</h3>)
        } else {
            View = () => (
                <>
                <div className='gfgfg-total-nfhfh rounded bcvdhdg'>
                    <h4>Orders Total: <b className='total-ncbcd'>K{orders.map(order => order.amount).reduce((x,y) => x+y)}</b>, <br></br>Orders: <b className='total-ncbcd-trsbs'>{orders.length}</b></h4>
                </div>
                <div className='gfgfg-total-nfhfh rounded bcvdhdg settled-bcvsbsv'>
                    <h4>Settled Orders Total: <b className='total-ncbcd'>K{orders.filter(order => order.settled === 1).length > 0 ? orders.filter(order => order.settled === 1).map(order => order.amount).reduce((x,y) => x+y) : 0}</b>,<br></br>Settled Orders: <b className='total-ncbcd-trsbs'>{orders.filter(order => order.settled === 1).length > 0 ? orders.filter(order => order.settled === 1).length : 0}</b></h4>
                </div>
                <div className='gfgfg-total-nfhfh rounded bcvdhdg un-settled-bcvsbsv'>
                    <h4>Un Settled Orders Total: <b className='total-ncbcd'>K{orders.filter(order => order.settled === 0).length > 0 ? orders.filter(order => order.settled === 0).map(order => order.amount).reduce((x,y) => x+y) : 0}</b>, <br></br>Un Settled Orders: <b className='total-ncbcd-trsbs'>{orders.filter(order => order.settled === 0).length > 0 ? orders.filter(order => order.settled === 0).length : 0}</b></h4>
                </div>
                <div className='fhdhfg-tablencgd'>
                    <table>
                        <thead>
                            <tr>
                                <th>OrderId</th>
                                <th>MerchantId</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Settled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders.map(order => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.merchantId}</td>
                                        <td><b className='total-ncbcd-mfj'>K{order.amount}</b></td>
                                        <td><b className='bcvdh-date'>{order.date}</b></td>
                                        <td className={order.settled ? 'settled-td0ncbvb':'un-settled-td0ncbvb'}>{order.settled ? 'YES':'NO'}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                </>
            )
        }
        StateUpdators.setTransactOrdersView({
            ready: true,
            View: () => (<View />)
        })
    },
    /**
     * 
     * @param {object} info The info object with the trans Id
     */
    validateTransId: async (info) => {
        console.log('[Utils.validateTransId]');
        Utils.showProgressBar('Validating...');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'validateTransId',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                StateUpdators.setTransIdValId(true);
                StateUpdators.setValidatorView({
                    View: () => (<ValidationViews.ValidationOk />)
                });
            } else {
                StateUpdators.setValidatorView({
                    View: () => (<ValidationViews.ValidationNotOk />)
                });
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Search agents
     * @param {string} searchQuery The search query to search
     */
    searchAgents: async (searchQuery) => {
        console.log('[Utils.searchAgents]');
        Utils.showProgressBar('Looking up...');
        StateUpdators.setAloadAtAll(false);
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'searchAgents',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        searchQuery: searchQuery
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                StateUpdators.setDescription('Found Results');
                StateUpdators.setSearchResults(true);
                StateUpdators.setAgents(typeof results.data.accountInfo !== 'undefined' ? [results.data.accountInfo] : results.data.searchResults);
                StateUpdators.setAgentDataStatus(true);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Search merchants
     * @param {string} searchQuery The search query to search
     */
    searchMerchants: async (searchQuery) => {
        console.log('[Utils.searchMerchants]');
        Utils.showProgressBar('Looking up...');
        StateUpdators.setAloadAtAll(false);
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'searchMerchants',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        searchQuery: searchQuery
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                StateUpdators.setDescription('Found Results');
                StateUpdators.setSearchResults(true);
                StateUpdators.setMerchants(typeof results.data.accountInfo !== 'undefined' ? [results.data.accountInfo] : results.data.searchResults);
                StateUpdators.setMerchatDataStatus(true);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Search merchants on the balances page/screen
     * @param {string} searchQuery The search query 
     */
    searchMerchantsOnBalances: async (searchQuery) => {
        console.log('[Utils.searchMerchantsOnBalances]');
        Utils.showProgressBar('Looking up...');
        StateUpdators.setLoadBalances(false);
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'searchMerchantsOnBalances',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        searchQuery: searchQuery
                    }
                }
            }
            let results = await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            });
            Utils.hideProgressBar();
            results = results.data;
            if(results.data.status) {
                StateUpdators.setMerchantBalances(results.data.searchResults);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch users for audience selecttion in system notices
     * @param {object} info The info object 
     */
    fetchAudienceUsers: async (info) => {
        console.log('[Utils.fetchAudienceUsers]');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchSystemNoticeAudiences',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            if(results.data.status) {
                StateUpdators.setUsersLoaded({
                    loaded: true,
                    users: results.data.users
                });
            } else {
                StateUpdators.setUsersLoaded({loaded: true,users: []});
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Fetch Topuphome resellers
     */
    fetchAudienceResellerUsers: async () => {
        console.log('[Utils.fetchAudienceResellerUsers]');
        const sessData = Utils.retrieveSession();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'fetchAudienceResellerUsers',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            if(results.data.status) {
                StateUpdators.setUsersLoaded({
                    loaded: true,
                    users: results.data.users
                });
            } else {
                StateUpdators.setUsersLoaded({loaded: true,users: []});
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.showFatalError(error.message);
        }
    },
    captureRichtextInput: (content) => {
        console.log('[Utils.captureRichtextInput]');
        if(!content.content) {
            Utils.showSidePopinError('Cannot save empty content','noContent');
        } else {
            Utils.richTextContent = content.content;
            Utils.showSidePopinSuccess('Content saved!');
        }
    },
    /**
     * Send a system notice
     * @param {object} info The info object with the info to use for sending the system notice
     */
    sendSystemNotice: async (info) => {
        console.log('[Utils.sendSystemNotice]');
        const sessData = Utils.retrieveSession();
        Utils.showProgressBar();
        try {
            const payload = {
                endpoint: 'Admin',
                payload: {
                    action: 'sendSystemNotice',
                    data: {
                        accessToken: sessData.accessToken,
                        csrfToken: sessData.csrfToken,
                        ...info,
                        emailContent: Utils.richTextContent,
                        ...Utils.audiences
                    }
                }
            }
            let results = (await axios.post(Utils.NeuronF.url,{
                data: payload
            }, {
                headers: {
                    TopuphomeSKey: Utils.NeuronF.apiKey
                }
            })).data;
            Utils.hideProgressBar();
            if(results.data.status) {
                Utils.showSidePopinSuccess(results.data.message);
            } else {
                Utils.showSidePopinError(typeof results.data.message !== 'undefined' ? results.data.message : results.data.errorMessage, results.data.error, window.location.href);
            }
        } catch (error) {
            Utils.hideProgressBar();
            Utils.showFatalError(error.message);
        }
    },
    /**
     * Display Topuphome transactions based on a selected status
     * @param {array} transactions The transactions array
     */
    allTopuphomeCoreTransactionsView: (transactions) => {
        console.log('[Utils.allTopuphomeCoreTransactionsView]');
        let View;
        if(transactions.length < 1) {
            View = () => (<h3>There are no transactions for the selected filter.</h3>)
        } else {
            View = () => (
                <>
                <div className='gfgfg-total-nfhfh rounded-top'>
                    <h4>Total Sales: <b className='total-ncbcd'>R{Number.parseFloat(transactions.map(transaction => transaction.amountToPay).reduce((x,y) => x+y)).toFixed(2)}</b>, Profit: <b className='total-ncbcd'>R{parseFloat(transactions.filter(trx => trx.status === 4).length*transactions[0].serviceFee).toFixed(2)}</b>, Charges: <b title='Payment Provider Total Charges' className='ptr total-ncbcd'>R{Number.parseFloat(transactions.map(transaction => transaction.charges).reduce((x,y) => x+y)).toFixed(2)}</b>, Transactions: <b className='total-ncbcd-trsbs'>{transactions.length}</b></h4>
                </div>
                <div className='fhdhfg-tablencgd'>
                    <table>
                        <thead>
                            <tr>
                                <th>TrxId</th>
                                <th className='pointer' title='Sent by'>UserId</th>
                                <th className='ptr pointer' title='Service Fee (Goes to Topuphome)'>Service Fee</th>
                                <th className='ptr pointer' title='Airtime Charge'>Airtime Charge</th>
                                <th className='pointer' title='Payment Processing Fee (Zero for TDGC, belongs to Payment Service Provider)'>Processing Fee</th>
                                <th>Total Charge</th>
                                <th>Recipient</th>
                                <th>Airtime Amount</th>
                                <th>Country</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th className='pointer' title='Status of the Transaction'>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                transactions.map(transaction => (
                                    <tr key={transaction.trxId} title='Click to view more info about this transaction' className='trx-hook pointer' onClick={(e) => {
                                        for (const val of Object.values(document.getElementsByClassName('trx-hook'))) {
                                            val.classList.remove('trx-clicked');
                                        }
                                        e.currentTarget.classList.add('trx-clicked');
                                        Utils.showTransactedUser(transaction);
                                    }}>
                                        <td>{transaction.trxId}</td>
                                        <td className='pointer' title={`Sent by user: ${transaction.firstname} ${transaction.lastname}`}>{transaction.userId}</td>
                                        <td><b className='total-ncbcd-mfj'>R{transaction.serviceFee}</b></td>
                                        <td><b className='total-ncbcd-mfj'>{transaction.amount ? `R${parseFloat(transaction.amount).toFixed(2)}`: 'NA'}</b></td>
                                        <td><b className='total-ncbcd-mfj'>R{parseFloat(transaction.charges).toFixed(2)}</b></td>
                                        <td><b className='total-ncbcd-mfj'>R{parseFloat(transaction.amountToPay).toFixed(2)}</b></td>
                                        <td>{transaction.recipient}</td>
                                        <td>MWK{parseFloat(transaction.airtimeAmount).toFixed(2)}</td>
                                        <td>{transaction.country}</td>
                                        <td><b className='bcvdh-date'>{transaction.date}</b></td>
                                        <td><b className='bcvdh-date'>{transaction.time}</b></td>
                                        <td className='ptr' title={Utils.trxStatuses[transaction.status]}>{transaction.status}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                </>
            )
        }
        StateUpdators.setTransactionsView({
            ready: true,
            View: () => (<View />)
        })
    },
    /**
     * Show the user who transacted
     * @param {object} info The info obejct
     */
    showTransactedUser: async (info) => {
        console.log('[Utils.showTransactedUser]');
        StateUpdators.setShowSingleUserOnTransactionsView({
            show: true,
            data: info
        })
    },
}

if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    Utils.NeuronF.url = 'https://staging.topuphome.co.za/serve/';
    console.log('On local dev environment, using staging API backend: ',Utils.NeuronF.url);
}

export default Utils;